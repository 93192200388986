<template>
  <div class="winner-page__main-content ">
    <div class="email-info-message-background--padding">
      <div class="expired-container-wrap">

        <CodeFillingWinnerformHero :winnerType="winnerType" />

        <!-- Már regisztrált -->
        <div
            class="expired-container second-background-inner--winner-messsage text-center form--status__content--expired"
            v-if="alreadyRegistered"
        >
          <div class="container-xl">
            <div class="row">
              <div class="col-12 col-lg-10 offset-lg-1 py-5">
                <div class="expired-container__inner py-5">
                  <div class="main-h1 display-5 text-dark fw-bold">
                    A nyereményedhez már megadtad az adataitad, köszönjük!
                  </div>
                  <!--<div class="codefilling-text display-3 pt-4">
                    <div class="text-dark font-secondary">
                      Mit kell tenned ezután?            
                    </div>
                  </div>-->
                  <div class="text-container font-secondary fw-bold py-3">
                    Az ellenőrzést követően 10 munkanapon belül visszaigazoljuk nyertességed. A visszaigazolás során fogjuk küldeni, hogy pontosan mikor és hogyan juthatsz hozzá nyereményedhez, így kérjük, kövesd figyelemmel e-mail postafiókod.
                  </div>
                  <!-- <div class="text-container winner-form-divider"></div>
                  <div class="btn-wrap m-md-1">
                    <button class="btn btn-primary mb-sm-5 mt-4 me-sm-4 btn-primary--large" @click="backToForm">
                      Új beküldés
                    </button>
                    <button class="btn btn-primary mb-5 mt-4 btn-primary--large" @click="backToSendings">
                      Beküldéseim
                    </button>
                  </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Sikeres adatfeltöltés -->
        <div id="success-data-upload"
             class="expired-container second-background-inner  text-center form--status__content--expired"
             v-if="!uploadedError && !alreadyRegistered && !formExpired && !formDisabled"
        >
          <div class="container-xl">
            <div class="row">
              <div class="col-12 py-5">
                <div class="expired-container__inner py-5">
                  <div class="main-h1 display-5 text-dark fw-bold">Köszönjük, adataidat sikeresen beküldted!</div>
                  <!--<div class="codefilling-text">
                    <div class="text-dark font-secondary display-3 py-3 fw-bold">
                  
                    </div>
                  </div>-->
                  <div class="text-container font-secondary fw-normal text-dark-blue">
                    Az ellenőrzést követően 10 munkanapon belül visszaigazoljuk nyertességed. A visszaigazolás során fogjuk küldeni, hogy pontosan mikor és hogyan juthatsz hozzá nyereményedhez, így kérjük, kövesd figyelemmel e-mail postafiókod.
                    <!--<div class=" pt-1">
                      <p class="text-container mt-3 mb-1 font-secondary">
                        Növeld az esélyed a Fődíj megnyerésére és tölts fel újabb kódokat 2022. július 31-én éjfélig!
                      </p>
                    </div>-->
                  </div>
                  <!-- <div class="text-container winner-form-divider--custom"></div>
                  <div class="btn-wrap m-md-1">
                    <button class="btn btn-primary mb-sm-5 btn-primary--large mt-4 me-sm-4" @click="backToForm">
                      Új beküldés
                    </button>
                    <button class="btn btn-primary mb-5 mt-4 btn-primary--large" @click="backToSendings">
                      Beküldéseim
                    </button>
                  </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- lejárt az idő -->
        <div
            class="expired-container second-background-inner  text-center form--status__content--expired"
            v-if="formExpired"
        >
          <div class="container-xl">
            <div class="row">
              <div class="col-12 py-5">
                <div class="expired-container__inner py-5">
                  <div class="main-h1 display-5 text-dark fw-bold">
                    A nyereményre való jogosultságodnak határideje lejárt.
                  </div>                 
                  <div class="font-secondary pt-3 text-container">
                    A <a
                      :href="$store.state.file_url"
                      style="text-decoration:underline; color:black; font-weight:bold"
                      target="_blank">Játékszabályzat</a> 8. pontja értelmében az adatok megadásának határideje lejárt, így helyetted tartaléknyertest sorsoltunk.<br/><br/>
                  <p class="form-expired-bold-text font-secondary fw-bold my-3">A nyereményjáték 2023. július 26-ig tart, újabb pályázatok feltöltésével továbbra is játszhatsz a nyereményekért.</p>
                  </div>
                  
                  <!--<div class="text-container winner-form-divider--custom"></div>
                    <div class="btn-wrap m-md-1">
                    <button class="btn btn-primary mt-3 btn-primary--large me-sm-4" @click="backToForm">
                      Új beküldés
                    </button>
                    <button class="btn btn-primary mt-3 btn-primary--large" @click="backToSendings">
                      Beküldéseim
                    </button>
                  </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Érvénytelen pályázat -->
        <div
            class="expired-container second-background-inner  text-center form--status__content--expired"
            v-if="formDisabled"
        >
          <div class="container-xl">
            <div class="row">
              <div class="col-12 py-5">
                <div class="expired-container__inner py-5">
                  <div class="main-h1 display-5 text-dark fw-bold">A pályázatod az ellenőrzés során valamilyen okból nem felelt meg.</div>                  
                  <!--<div class="email-info-text--small text-container font-secondary">
                    A <a :href="`/pdf/Jatekszabalyzat.pdf?` + TimeStamp"
                         style="text-decoration:underline; color:black; font-weight:bold" target="_blank">Játékszabályzatban</a>
                    rendelkezései alapján pótnyertest sorsoltunk.<br/><br/>
                  </div>-->
                  <p class="font-secondary text-container">
                    Kérjük, ellenőrizd postafiókod a további információkért.
                  </p>                  
                  <!--<div class="btn-wrap m-md-1">
                    <button class="btn btn-primary mt-3 btn-primary--large" @click="backToForm">
                      Újabb kódot töltök fel
                    </button>
                  </div>-->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import CodeFillingWinnerformHero from '@/components/CodeFillingWinnerFormHero.vue'

export default {
  name: "FormExpiredAndRegistered",
  props: ['formExpired','alreadyRegistered', 'formDisabled', 'uploadedWinner', 'uploadedError', 'winnerType'],
    data() {
    return {
      baseUrl: process.env.VUE_APP_BASE_URL,
    }
  },
  components:{
    CodeFillingWinnerformHero
  },
  methods: {
    backToForm() {
      this.$router.push("/#palyazat");
      window.scrollTo(0, 0);
    },
    backToSendings(){
      this.$router.push("/#bekuldeseim");
      window.scrollTo(0, 0);
    }
  }  
};
</script>
